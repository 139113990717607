'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider", function($stateProvider) {
        $stateProvider
            .state('index.case-list', {
                url: '/case-list?searchValue&&openedTab&&active&&tag&&pageno&&reference_query&&bench_type&&subject_subject&&subject_section&&party_respond_type&&partypetitioner__name&&judge_name&&counsel_name&&judgement_date_start_date&&judgement_date_end_date&&judgement_date_exact_date&&page_number_page_number&&page_number_volume&&page_number_year&&court_court&&writ_petition_query&&party_petitioner_name&&party_respondent_name&&search_id&&note_id&&saved_tab_id&&saveTabsFromOtherPage&&otherPage&&orderOfFilters&&',
                templateUrl: 'app/case-detail/case.html',
                controller: 'CaseCtrl'
            });
    }]);